const Config = {
  formSchema() {
    return {
      full_name: 'string',
      email: 'string',
      phone_number: 'string',
      status: true,
      total: 0,
      collection_id: 0,
      payment_record_id: 0,
      total_amount_with_fee: 0,
      transaction_fee: 0,
      quantity: 0,
      account_id: 0,
      invoice_no: 'string',
      customer_id: 0,
      transaction_invoice_no: 'string',
      invoice_status_id: 0,
      invoice_status: 'string',
    }
  },
  formData() {
    return {
      title: {
        edit: 'Edit Customer Bill',
        view: 'View Customer Bill',
        remove: 'Remove Customer Bill',
        create: 'Create New Customer Bill',
      },
      form: [{
        formContext: 'customer Bill',
        formName: 'customer Bill',
        elem: [
          {
            key: 'full_name',
            fieldName: 'Full Name',
            type: 'text',
            rules: 'required',
            show: {
              edit: true, remove: true, view: true, create: true,
            },
            colSize: { lg: 12 },
          },
          {
            key: 'email',
            fieldName: 'Email',
            type: 'text',
            rules: 'required',
            show: {
              edit: true, remove: true, view: true, create: true,
            },
            colSize: { lg: 12 },
          },
          {
            key: 'phone_number',
            fieldName: 'Phone Number',
            type: 'text',
            rules: 'required',
            show: {
              edit: true, remove: true, view: true, create: true,
            },
            colSize: { lg: 12 },
          },
          {
            key: 'status',
            fieldName: 'Status',
            type: 'text',
            rules: 'required',
            show: {
              edit: true, remove: true, view: true, create: true,
            },
            colSize: { lg: 12 },
          },
          {
            key: 'total',
            fieldName: 'Total',
            type: 'text',
            rules: 'required',
            show: {
              edit: true, remove: true, view: true, create: true,
            },
            colSize: { lg: 12 },
          },
          {
            key: 'collection_id',
            fieldName: 'Collection Id',
            type: 'text',
            rules: 'required',
            show: {
              edit: true, remove: true, view: true, create: true,
            },
            colSize: { lg: 12 },
          },
          {
            key: 'payment_record_id',
            fieldName: 'Payment Record Id',
            type: 'text',
            rules: 'required',
            show: {
              edit: true, remove: true, view: true, create: true,
            },
            colSize: { lg: 12 },
          },
          {
            key: 'total_amount_with_fee',
            fieldName: 'Total Amount With Fee',
            type: 'text',
            rules: 'required',
            show: {
              edit: true, remove: true, view: true, create: true,
            },
            colSize: { lg: 12 },
          },
          {
            key: 'transaction_fee',
            fieldName: 'Transaction Fee',
            type: 'text',
            rules: 'required',
            show: {
              edit: true, remove: true, view: true, create: true,
            },
            colSize: { lg: 12 },
          },
          {
            key: 'quantity',
            fieldName: 'Quantity',
            type: 'text',
            rules: 'required',
            show: {
              edit: true, remove: true, view: true, create: true,
            },
            colSize: { lg: 12 },
          },
          {
            key: 'account_id',
            fieldName: 'Account Id',
            type: 'text',
            rules: 'required',
            show: {
              edit: true, remove: true, view: true, create: true,
            },
            colSize: { lg: 12 },
          },
          {
            key: 'invoice_no',
            fieldName: 'Invoice No',
            type: 'text',
            rules: 'required',
            show: {
              edit: true, remove: true, view: true, create: true,
            },
            colSize: { lg: 12 },
          },
          {
            key: 'customer_id',
            fieldName: 'Customer Id',
            type: 'text',
            rules: 'required',
            show: {
              edit: true, remove: true, view: true, create: true,
            },
            colSize: { lg: 12 },
          },
          {
            key: 'transaction_invoice_no',
            fieldName: 'Transaction Invoice No',
            type: 'text',
            rules: 'required',
            show: {
              edit: true, remove: true, view: true, create: true,
            },
            colSize: { lg: 12 },
          },
          {
            key: 'invoice_status_id',
            fieldName: 'Invoice Status Id',
            type: 'text',
            rules: 'required',
            show: {
              edit: true, remove: true, view: true, create: true,
            },
            colSize: { lg: 12 },
          },
          {
            key: 'invoice_status',
            fieldName: 'Invoice Status',
            type: 'text',
            rules: 'required',
            show: {
              edit: true, remove: true, view: true, create: true,
            },
            colSize: { lg: 12 },
          },
        ],
      }],
    }
  },
  tableData() {
    return {
      tableAction: [
        {
          enable: false, param: 'id', btnLabel: 'View', action: 'view', btnIcon: 'SearchIcon', btnOnly: true, variant: 'primary', size: 'sm', iconSize: '12',
        },
        {
          enable: true, param: 'id', btnLabel: 'Edit', action: 'edit', btnIcon: 'Edit2Icon', btnOnly: true, variant: 'info', size: 'sm', iconSize: '12',
        },
        {
          enable: false, param: 'id', btnLabel: 'Remove', action: 'remove', btnIcon: 'Trash2Icon', btnOnly: true, variant: 'danger', size: 'sm', iconSize: '12',
        },
      ],
      tableHeader: [
        {
          key: 'created_at', label: 'Created At', sortable: true, visible: true,
        },
        {
          key: 'full_name', label: 'Full Name', sortable: true, visible: true,
        },
        {
          key: 'email', label: 'Email', sortable: true, visible: true,
        },
        {
          key: 'phone_number', label: 'Phone Number', sortable: true, visible: true,
        },
        {
          key: 'total',
          label: 'Total',
          sortable: true,
          visible: true,
          class: 'text-right',
        },
        {
          key: 'total_amount_with_fee',
          label: 'Total Amount With Fee',
          sortable: true,
          visible: false,
          class: 'text-right',
        },
        {
          key: 'quantity', label: 'Quantity', sortable: true, visible: true,
        },
        {
          key: 'invoice_no', label: 'url', sortable: true, visible: true,
        },
        {
          key: 'invoice_status', label: 'Invoice Status', sortable: true, visible: true,
        },
        {
          key: 'invoice_status_id', label: 'Invoice Status Id', sortable: true, visible: false,
        },
        {
          key: 'status', label: 'Status', sortable: true, visible: false,
        },
        {
          key: 'updated_at', label: 'Updated At', sortable: true, visible: false,
        },
        {
          key: 'invoice_status', label: 'Invoice Status', sortable: true, visible: false,
        },
        {
          key: 'collection_id', label: 'Collection Id', sortable: true, visible: false,
        },
        {
          key: 'payment_record_id', label: 'Payment Record Id', sortable: true, visible: false,
        },
        {
          key: 'transaction_fee',
          label: 'Transaction Fee',
          sortable: true,
          visible: false,
          class: 'text-right',
        },
        {
          key: 'id', label: 'Id', sortable: true, visible: false,
        },
        {
          key: 'customer_id', label: 'Customer Id', sortable: true, visible: false,
        },
        {
          key: 'account_id', label: 'Account Id', sortable: true, visible: false,
        },
        {
          key: 'record_status', label: 'Record Status', sortable: true, visible: false,
        },
        {
          key: 'transaction_invoice_no', label: 'Transaction Invoice No', sortable: true, visible: false,
        },
        {
          key: 'actions',
          label: 'Action',
          class: 'text-right',
          visible: false,
        },
      ],
    }
  },
}

export default Config
