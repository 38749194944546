const Config = {
  formSchema() {
    return {
      name: 'string',
      start_date: '2022-01-19T04:17:09.106Z',
      end_date: '2022-01-19T04:17:09.106Z',
      account_usage_limit: 'string',
      account_id: 0,
    }
  },
  formData() {
    return {
      title: {
        edit: 'Edit Discount',
        view: 'View Discount',
        remove: 'Remove Discount',
        create: 'Create New Discount',
      },
      form: [{
        formContext: 'discount',
        formName: 'discount',
        elem: [
          {
            key: 'name',
            fieldName: 'Name',
            type: 'text',
            rules: 'required',
            show: {
              edit: true, remove: true, view: true, create: true,
            },
            colSize: { lg: 12 },
          },
          {
            key: 'start_date',
            fieldName: 'Start Date',
            type: 'datepicker',
            rules: 'required',
            datePickerOption: {
              dateFormat: 'Z',
            },
            show: {
              edit: true, remove: true, view: true, create: true,
            },
            colSize: { lg: 12 },
          },
          {
            key: 'end_date',
            fieldName: 'End Date',
            type: 'datepicker',
            rules: 'required',
            datePickerOption: {
              dateFormat: 'Z',
            },
            show: {
              edit: true, remove: true, view: true, create: true,
            },
            colSize: { lg: 12 },
          },
          {
            key: 'account_usage_limit',
            fieldName: 'Account Usage Limit',
            type: 'text',
            rules: 'required',
            show: {
              edit: true, remove: true, view: true, create: true,
            },
            colSize: { lg: 12 },
          },
        ],
      }],
    }
  },
  tableData() {
    return {
      tableAction: [
        {
          enable: false, param: 'id', btnLabel: 'View', action: 'view', btnIcon: 'SearchIcon', btnOnly: true, variant: 'primary', size: 'sm', iconSize: '12',
        },
        {
          enable: true, param: 'id', btnLabel: 'Edit', action: 'edit', btnIcon: 'Edit2Icon', btnOnly: true, variant: 'info', size: 'sm', iconSize: '12',
        },
        {
          enable: false, param: 'id', btnLabel: 'Remove', action: 'remove', btnIcon: 'Trash2Icon', btnOnly: true, variant: 'danger', size: 'sm', iconSize: '12',
        },
      ],
      tableHeader: [
        {
          key: 'id', label: 'Id', sortable: true, visible: true,
        },
        {
          key: 'start_date', label: 'Start Date', sortable: true, visible: true,
        },
        {
          key: 'account_usage_limit', label: 'Account Usage Limit', sortable: true, visible: true,
        },
        {
          key: 'account_id', label: 'Account Id', sortable: true, visible: true,
        },
        {
          key: 'created_at', label: 'Created At', sortable: true, visible: true,
        },
        {
          key: 'name', label: 'Name', sortable: true, visible: true,
        },
        {
          key: 'end_date', label: 'End Date', sortable: true, visible: true,
        },
        {
          key: 'rule_id', label: 'Rule Id', sortable: true, visible: true,
        },
        {
          key: 'record_status', label: 'Record Status', sortable: true, visible: true,
        },
        {
          key: 'updated_at', label: 'Updated At', sortable: true, visible: true,
        },
        {
          key: 'actions',
          label: 'Action',
          class: 'text-right',
          visible: true,
        },
      ],
    }
  },
}

export default Config
