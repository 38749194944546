<template>
  <b-card no-body>
    <template #header>
      <h4 class="mr-auto mb-0">
        {{ cardTitle }}
      </h4>
    </template>
    <b-row
      v-if="!!tableSettings"
      class="border-top"
    >
      <b-col cols="6">
        <b-form-group
          class="my-2"
          label-size="sm"
        >
          <template v-if="tableSettings.refresh">
            <b-button
              size="sm"
              class="mr-1 ml-1"
              @click="initDataGrid(dataGridEndpoint)"
            >
              <i class="fa fa-sync" /> Refresh
            </b-button>
          </template>
          <template v-if="tableSettings.refresh">
            <b-form-select
              v-model="selectedPerPage"
              class="w-25"
              :options="perPageOptions"
              size="sm"
              @change="initDataGrid(dataGridEndpoint)"
            />
          </template>
        </b-form-group>
      </b-col>
      <b-col cols="6">
        <b-form-group
          class="ml-2 mt-2 mb-2 mr-0"
          label-cols-sm="3"
          label-align-sm="right"
          label-size="sm"
        >
          <template v-if="tableSettings.filter">
            <b-col>
              <b-input-group size="sm">
                <b-form-input
                  v-model="filter"
                  type="text"
                  placeholder="Type to filter"
                />
                <b-input-group-append>
                  <b-button
                    :disabled="!filter"
                    @click="filter = ''"
                  >Clear</b-button>
                </b-input-group-append>
              </b-input-group>
            </b-col>
          </template>
          <template v-if="tableSettings.search">
            <b-col>
              <b-row>
                <b-col cols="4"><b-form-select
                  v-model="searchField"
                  :options="searchFieldOpts"
                  size="sm"
                /></b-col>
                <b-col>
                  <b-input-group size="sm">
                    <b-form-input
                      v-model="searchTerm"
                      type="text"
                      placeholder="Type to Search"
                    />

                    <b-input-group-append>
                      <b-button
                        v-if="searchTerm"
                        @click="initDataGrid()"
                      >Clear</b-button>
                      <b-button
                        :disabled="!searchTerm"
                        @click="searchGrid()"
                      >Search</b-button>
                    </b-input-group-append>
                  </b-input-group>
                </b-col>
              </b-row>

            </b-col>

          </template>
        </b-form-group>
      </b-col>
    </b-row>
    <b-table
      :filter="filter"
      :no-local-sorting="true"
      striped
      bordered
      responsive
      show-empty
      hover
      :items="GridData"
      :busy="isBusy"
      :sort-by.sync="sortBy"
      :sort-desc.sync="sortDesc"
      :fields="TableHeader"
      @sort-changed="sortingChanged"
    >
      <template #table-busy>
        <div class="text-center my-2">
          <b-spinner class="align-middle" />
          <br>
          <strong>Loading...</strong>
        </div>
      </template>

      <template #cell(payment_date)="row">
        {{ transformDate(row.item.payment_date, false, 'FF' ) }}
      </template>

      <template #cell(record_status)="row">
        <span :class="[{'text-success': row.item.record_status === 1}, {'text-warning': row.item.record_status === 2}]">
          {{ displaySystemConstantsLabel('record_status', row.item.record_status) }}</span>
      </template>

      <template #cell(payment_method)="row">
        <!--        {{ row }}-->
        {{ humanise(row.item.payment_method) }}
      </template>
      <template #cell(payment_mode)="row">
        <!--        {{ row }}-->
        {{ humanise(row.item.payment_mode) }}
      </template>
      <template #cell(transaction_fee)="row">
        <vue-numeric
          read-only
          :precision="2"
          :currency="globalCurrency"
          separator=","
          :value="row.item.transaction_fee"
        />
      </template>
      <template #cell(amount)="row">
        <vue-numeric
          read-only
          :precision="2"
          :currency="globalCurrency"
          separator=","
          :value="row.item.amount"
        />
      </template>

      <template #cell(actions)="row">
        <template v-if="TableActions.length > 0">
          <b-button-group size="sm">
            <template v-for="(btn,index) in TableActions">
              <template v-if="btn.action === 'view'">
                <b-button
                  :key="`button_actions-${index}`"
                  :variant="btn.variant"
                  @click="viewRow(row.item[btn.param])"
                >
                  <feather-icon
                    v-if="btn.btnIcon"
                    :size="btn.iconSize"
                    :icon="btn.btnIcon"
                  />
                  <template v-if="!btn.btnOnly">
                    {{ btn.btnLabel }}
                  </template>
                </b-button>
              </template>
              <template v-if="btn.action === 'edit'">
                <b-button
                  :key="`button_actions-${index}`"
                  :variant="btn.variant"
                  @click="editRow(row.item[btn.param])"
                >
                  <feather-icon
                    v-if="btn.btnIcon"
                    :size="btn.iconSize"
                    :icon="btn.btnIcon"
                  />
                  <template v-if="!btn.btnOnly">
                    {{ btn.btnLabel }}
                  </template>
                </b-button>
              </template>
              <template v-if="btn.action === 'remove'">
                <b-button
                  :key="`button_actions-${index}`"
                  :variant="btn.variant"
                  @click="deleteRow(row.item[btn.param])"
                >
                  <feather-icon
                    v-if="btn.btnIcon"
                    :size="btn.iconSize"
                    :icon="btn.btnIcon"
                  />
                  <template v-if="!btn.btnOnly">
                    {{ btn.btnLabel }}
                  </template>
                </b-button>
              </template>

            </template>
          </b-button-group>
        </template>

      </template>
    </b-table>

    <div class="row">
      <div
        v-if="!isBusy"
        class="col"
      >
        <label class="p-2">
          Showing {{ (currentPage - 1) * selectedPerPage + 1 }} to
          <!-- check if last page-->
          <template
            v-if="
              Math.ceil(tableSettings.rows / selectedPerPage) ===
                currentPage
            "
          >
            {{ GridSettings.recordsFiltered }}
          </template>
          <template v-if="currentPage === 1 && GridSettings.draw < selectedPerPage">
            {{ GridSettings.draw }}
          </template>
          <template v-if="currentPage !== 1 && GridSettings.recordsFiltered === selectedPerPage * currentPage">
            {{ selectedPerPage * currentPage }}
          </template>
          of
          <template
            v-if="
              GridSettings.recordsTotal !== GridSettings.recordsFiltered
            "
          >
            {{ GridSettings.recordsFiltered }}
            <template v-if="GridSettings.recordsFiltered > 1">entries</template>
            <template v-else>entry</template>
          </template>
          <template v-else>
            {{ GridSettings.recordsTotal }}

            <template v-if="GridSettings.recordsTotal > 1">entries</template>
            <template v-else>entry</template>
          </template>
        </label>
        <template
          v-if="GridSettings.recordsTotal !== GridSettings.recordsFiltered"
        >
          <label>(filtered from {{ GridSettings.recordsTotal }} total
            <template v-if="GridSettings.recordsTotal > 1">entries</template>
            <template v-else>entry</template>
            )</label>
        </template>
      </div>
      <div class="col">
        <b-pagination
          v-if="searchField === ''"
          v-model="currentPage"
          class="pl-0 justify-content-end mt-2 mr-1"
          first-number
          last-number
          :total-rows="tableSettings.rows"
          :per-page="tableSettings.perPage"
          @change="pagination($event)"
        />

        <b-pagination
          v-if="searchField !== ''"
          v-model="currentPage"
          class="pl-0 justify-content-end mt-2 mr-1"
          first-number
          last-number
          :total-rows="GridSettings.recordsFiltered"
          :per-page="tableSettings.perPage"
          @change="pagination($event)"
        />
      </div>
    </div>

    <pre v-if="debugMode">{{ GridSettings }}</pre>
  </b-card>

</template>

<script>
import Config from '@/views/admin/discounts/formConfig'
import humaniseMixin from '@/common/humanise.mixin'

const tableAction = [
  {
    enable: true, param: 'id', btnLabel: 'View', action: 'view', btnIcon: 'SearchIcon', btnOnly: true, variant: 'primary', size: 'sm', iconSize: '12',
  },
  {
    enable: false, param: 'id', btnLabel: 'Edit', action: 'edit', btnIcon: 'Edit2Icon', btnOnly: true, variant: 'info', size: 'sm', iconSize: '12',
  },
  {
    enable: false, param: 'id', btnLabel: 'Remove', action: 'remove', btnIcon: 'Trash2Icon', btnOnly: true, variant: 'danger', size: 'sm', iconSize: '12',
  },
]

const tableHeader = [
  {
    key: 'created_at', label: 'Created At', sortable: true, visible: false,
  },

  {
    key: 'payment_date', label: 'Payment Date', sortable: true, visible: true,
  },
  {
    key: 'id', label: 'Id', sortable: true, visible: false,
  },
  {
    key: 'reference_number', label: 'Reference Number', sortable: true, visible: true,
  },
  {
    key: 'payment_method', label: 'Payment Method', sortable: true, visible: true,
  },
  {
    key: 'payment_mode', label: 'Payment Mode', sortable: true, visible: true,
  },
  {
    key: 'transaction_fee',
    label: 'Transaction Fee',
    sortable: true,
    visible: true,
    class: 'text-right',
  },
  {
    key: 'amount',
    label: 'Amount',
    sortable: true,
    visible: true,
    class: 'text-right',
  },
  {
    key: 'total',
    label: 'Total',
    sortable: true,
    visible: false,
    class: 'text-right',
  },
  {
    key: 'kind', label: 'Kind', sortable: true, visible: false,
  },
  {
    key: 'paid_at', label: 'Paid At', sortable: true, visible: false,
  },
  {
    key: 'customer_id', label: 'Customer Id', sortable: true, visible: false,
  },
  {
    key: 'updated_at', label: 'Updated At', sortable: true, visible: false,
  },
  {
    key: 'bank_code', label: 'Bank Code', sortable: true, visible: false,
  },
  {
    key: 'net_amount',
    label: 'Net Amount',
    sortable: true,
    visible: false,
    class: 'text-right',
  },
  {
    key: 'record_status', label: 'Record Status', sortable: true, visible: true,
  },
  {
    key: 'actions',
    label: 'Action',
    class: 'text-right',
    visible: false,
  },
]

export default {
  components: {},
  mixins: [humaniseMixin],
  data() {
    return {
      cardTitle: 'Recent Payment',
      dataGridEndpoint: [],
      module: 'paymentRecords',

      // DataGridSetting
      filter: '',
      searchTerm: '',
      currentPage: 1,
      record_status: 1, // active only
      isBusy: true,
      selectedPerPage: 50,
      sortBy: 'created_at',
      sortDesc: true,

      searchField: 'reference_number',

      searchFieldOpts: [
        { value: 'reference_number', text: 'Reference Number' },
        { value: 'payment_method', text: 'Payment Method' },
        { value: 'payment_mode', text: 'Payment Mode' },
      ],
      perPageOptions: [
        { value: 10, text: '10' },
        { value: 25, text: '25' },
        { value: 50, text: '50' },
        { value: 100, text: '100' },
      ],
    }
  },
  computed: {

    formSchema() {
      if (Config) {
        return Config.formSchema()
      }
      return {}
    },
    tableConfig() {
      if (Config) {
        return Config.tableData()
      }
      return {}
    },
    tableSettings() {
      const list = this.$store.getters['merchant/getPaginationInfoByStateKey'](['merchantPaymentRecords'])
      // const { list } = dummyRes.data
      const perPage = this.selectedPerPage
      let listLength
      if (list !== null) {
        listLength = this.GridSettings.recordsTotal
      } else {
        listLength = 100
      }
      return {
        rows: listLength,
        perPage,
        refresh: true,
        filter: false,
        pagination: true,
        search: true,
      }
    },
    TableHeader() {
      const x = tableHeader
      return x.filter(y => y.visible)
    },
    TableActions() {
      const x = tableAction
      return x.filter(y => y.enable)
    },
    sortObj() {
      const obj = {
        parameter_name: this.sortBy,
        sort_type: this.sortDesc ? 'desc' : 'asc',
      }
      return obj
    },
    GridData() {
      const list = this.$store.getters['merchant/getMerchantPaymentRecords']
      if (list !== null) {
        return list
      }
      return []
    },
    GridSettings() {
      const list = this.$store.getters['merchant/getPaginationInfoByStateKey'](['merchantPaymentRecords'])

      console.log('---->', list)
      if (list !== null) {
        return {
          draw: list.draw,
          recordsTotal: list.record_total,
          next_page: list.next_page,
          next_page_length: list.next_page_length,
          next_page_start: list.next_page_start,
          previous_page_length: list.previous_page_length,
          previous_page_start: list.previous_page_start,
          previouse_page: list.previouse_page,
          recordsFiltered: list.record_filtered,
        }
      }
      return []
    },
  },
  async mounted() {
    this.isBusy = false

    const payload = {
      limit: this.tableSettings.perPage,
      skip: 0,
      record_status: 1,
      search: {
        search_enable: false,
        search_key: '',
        search_column: '',
        search_replace_word_enable: true,
        search_word_replace: ' ',
        search_word_replace_to: '_',
      },
      sort: this.sortObj,
    }

    await this.$store.dispatch('merchant/getMerchantPaymentRecords', payload)
  },
  methods: {

    displaySystemConstantsLabel(key, id = null) {
      const recordStatusList = this.$store.getters['systemConstants/getSystemConstantsByKey'](key)

      if (Array.isArray(recordStatusList) && recordStatusList.length && id !== null) {
        const x = recordStatusList.filter(el => el.value === id)
        return this.humanise(
          x[0].name,
        )
      }
      return 'NA'
    },
    editRow(itemId) {
      console.log('edit', itemId)
      this.$router.push({
        name: 'administration-discounts-form',
        params: { id: itemId, mode: 'edit' },
      })
    },
    deleteRow(itemId) {
      console.log('delete', itemId)
    },
    viewRow(itemId) {
      console.log('view', itemId)
      this.$router.push({
        name: 'administration-discounts-form',
        params: { id: itemId, mode: 'view' },
      })
    },
    create() {
      this.$router.push({
        name: 'administration-discounts-new',
      })
    },
    pagination(e) {
      const payload = {
        limit: this.tableSettings.perPage,
        skip: (e - 1) * this.tableSettings.perPage,
        record_status: 1,
        search: {
          search_enable: false,
          search_key: this.searchTerm,
          search_column: this.searchField,
          search_replace_word_enable: true,
          search_word_replace: ' ',
          search_word_replace_to: '_',
        },
        sort: this.sortObj,
      }
      //     {
      //   start: (e - 1) * this.tableSettings.perPage,
      //   length: this.tableSettings.perPage,
      //   sort: this.sortObj,
      //   search: this.searchTerm,
      // }
      this.isBusy = true
      this.$store.dispatch('merchant/getMerchantPaymentRecords', payload).then(() => {
        this.isBusy = false
      })
    },
    sortingChanged(ctx) {
      this.sortDesc = !this.sortDesc
      this.sortBy = ctx.sortBy
      const payload = {
        limit: this.tableSettings.perPage,
        skip: 0,
        record_status: 1,
        search: {
          search_enable: false,
          search_key: this.searchTerm,
          search_column: this.searchField,
          search_replace_word_enable: true,
          search_word_replace: ' ',
          search_word_replace_to: '_',
        },
        sort: this.sortObj,
      }
      this.isBusy = true
      this.$store.dispatch('merchant/getMerchantPaymentRecords', payload).then(() => {
        this.currentPage = 1
        this.isBusy = false
      })
    },
    searchGrid() {
      const payload = {
        limit: this.tableSettings.perPage,
        skip: 0,
        search: {
          search_enable: true,
          search_key: this.searchTerm,
          search_column: this.searchField,
          search_replace_word_enable: true,
          search_word_replace: ' ',
          search_word_replace_to: '_',
        },
        sort: this.sortObj,
      }
      this.isBusy = true
      this.$store.dispatch('merchant/getMerchantPaymentRecords', payload).then(() => {
        this.currentPage = 1
        this.isBusy = false
      })
    },
    initDataGrid() {
      this.isBusy = true
      this.searchTerm = ''
      const payload = {
        limit: this.tableSettings.perPage,
        skip: 0,
        record_status: this.record_status,
        search: {
          search_enable: false,
          search_key: '',
          search_column: '',
          search_replace_word_enable: true,
          search_word_replace: ' ',
          search_word_replace_to: '_',
        },
        sort: this.sortObj,
      }

      this.$store.dispatch('merchant/getMerchantPaymentRecords', payload).then(() => {
        this.isBusy = false
      })
    },
  },
}
</script>
